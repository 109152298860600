export default {
  namespaced: true,
  state: {
    darkTheme: false,
    isLoggedIn: false,
    userData: null
  },
  mutations: {
    toggleDarkTheme(state) {
      state.darkTheme = !state.darkTheme
    },
    logIn(state, data) {
      state.isLoggedIn = true
      state.userData = data
    },
    logOut(state) {
      state.isLoggedIn = false
      state.userData = null
    }
  },
  getters: {
    userData(state) {
      return state.userData
    }
  }
}
