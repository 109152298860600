<template>
  <v-app>
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      :duration="300"
    >
      <LoginOrSingup v-if="this.$route.path === '/login'">
        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          :duration="300"
        >
          <router-view />
        </transition>
      </LoginOrSingup>
      <SideNavigation v-else>
        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          :duration="300"
        >
          <router-view />
        </transition>
      </SideNavigation>
    </transition>
    <Overlay />
    <CustomSnackbar />
  </v-app>
</template>

<script>
import SideNavigation from '@/layouts/SideNavigation'
import LoginOrSingup from '@/layouts/LoginOrSingup'
import Overlay from '@/components/Overlay'
import CustomSnackbar from '@/components/CustomSnackbar'
import { mapState } from 'vuex'

export default {
  name: 'Manpower',

  components: {
    SideNavigation,
    LoginOrSingup,
    Overlay,
    CustomSnackbar
  },
  data() {
    return {
      layout: 'div'
    }
  },
  created() {
    this.$vuetify.theme.dark = this.darkTheme
  },
  computed: {
    ...mapState('account', ['darkTheme'])
  },
  watch: {
    async darkTheme(state) {
      this.$vuetify.theme.dark = state
    }
  }
}
</script>
