import Vue from 'vue'
import Vuex from 'vuex'
import account from './account'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    overlay: false,
    snackbar: {
      visible: false,
      color: 'success',
      text: null,
      icon: null,
      timeout: 3000,
      multiline: false
    }
  },
  mutations: {
    showOverlay(state) {
      state.overlay = true
    },
    hideOverlay(state) {
      state.overlay = false
    },
    showSnackBar(state, snackbarConfig) {
      state.snackbar.visible = true
      'color' in snackbarConfig
        ? (state.snackbar.color = snackbarConfig.color)
        : false
      'text' in snackbarConfig
        ? (state.snackbar.text = snackbarConfig.text)
        : false
      'timeout' in snackbarConfig
        ? (state.snackbar.timeout = snackbarConfig.timeout)
        : false
      'text' in snackbarConfig && snackbarConfig.text.length > 50
        ? (state.snackbar.multiline = snackbarConfig.multiline)
        : false
      'icon' in snackbarConfig
        ? (state.snackbar.icon = snackbarConfig.icon)
        : false
    },
    closeSnackBar(state) {
      state.snackbar.visible = false
      state.snackbar.multiline = false
      state.snackbar.text = null
      state.snackbar.icon = null
    }
  },
  actions: {},
  modules: {
    account
  },
  plugins: [
    createPersistedState({
      paths: ['account.darkTheme', 'account.isLoggedIn', 'account.userData']
    })
  ]
})
