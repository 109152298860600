import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'animate.css'
import ScrollFadeIn from './directives/scrollfadein'
import VueAxios from 'vue-axios'
import axios from './plugins/axios'
import dateformat from 'dateformat'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import Vue2Filters from 'vue2-filters'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

import '@/assets/scss/custom.scss'
import './registerServiceWorker'

Vue.directive('scrollfadein', ScrollFadeIn)
Vue.use(VueAxios, axios)
Vue.use(Vue2Filters)
Vue.component('vue-custom-scrollbar', vueCustomScrollbar)

// let envBaseUrl = 'https://inndotsadecv-dev.outsystemscloud.com/'
let envBaseUrl = 'https://inndotsadecv.outsystemscloud.com/'

Vue.prototype.$appName = 'APP VENTAS'
// Vue.prototype.$loginBaseUrl = envBaseUrl + 'CRM/rest/'
// Vue.prototype.$endpointsBaseUrl = envBaseUrl + 'CoreCRM/rest/'
Vue.prototype.$loginBaseUrl = envBaseUrl + 'ROFFCRM/rest/'
Vue.prototype.$endpointsBaseUrl = envBaseUrl + 'ROFF/rest/'

Vue.config.productionTip = true

new Vue({
  router,
  store,
  vuetify,
  dateformat,
  render: (h) => h(App)
}).$mount('#app')
