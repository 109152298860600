<template>
  <v-snackbar
    v-model="snackbar.visible"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    top
  >
    <v-icon class="mr-2" v-if="snackbar.icon">{{ snackbar.icon }}</v-icon
    >{{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="$store.commit('closeSnackBar')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  computed: {
    snackbar() {
      return this.$store.state.snackbar
    }
  },
  methods: {
    ...mapMutations(['closeSnackBar'])
  }
}
</script>
