import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // primary: '#476EA5',
        // error: '#AB414C',
        // info: '#6390C5',
        // success: '#6E8F82',
        // warning: '#E77C22',
        // accent: '#6390C5'
      },
      dark: {
        primary: '#476EA5',
        error: '#AB414C',
        info: '#6390C5',
        success: '#6E8F82',
        warning: '#E77C22',
        accent: '#6390C5'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
})
