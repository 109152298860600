import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    props: true,
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/myOpportunities',
    name: 'myOpportunities',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "myOpportunities" */ '../views/MyOpportunities.vue'
      )
  },
  {
    path: '/opportunity/:id',
    name: 'opportunity',
    props: true,
    component: () =>
      import(/* webpackChunkName: "opportunity" */ '../views/Opportunity.vue')
  },
  {
    path: '/newOpportunity',
    name: 'newOpportunity',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "newOpportunity" */ '../views/NewOpportunity.vue'
      )
  },
  {
    path: '/myIndicators',
    name: 'myIndicators',
    props: true,
    component: () =>
      import(/* webpackChunkName: "myIndicators" */ '../views/MyIndicators.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
