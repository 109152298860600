import axios from 'axios'
import store from '../store/index'
import router from '../router/index'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

axios.interceptors.request.use((request) => {
  // do something with request meta data, configuration, etc
  if (store.getters['account/userToken'] != null) {
    request.headers.common.token = store.getters['account/userToken']
  }
  request.config = {
    ...(request.config ?? {}) // preserve a given request.config object
  }
  if (request.config.showOverlay == true) {
    store.commit('showOverlay')
  }
  return request
})

// doing something with the response
axios.interceptors.response.use(
  (response) => {
    // console.log(response);
    if (response.config.showOverlay == true) {
      store.commit('hideOverlay')
    }
    return response
  },
  (error) => {
    if (error.response.config.showOverlay == true) {
      store.commit('hideOverlay')
    }

    let msg =
      'Errors' in error.response.data
        ? error.response.data.Errors[0]
        : 'Ocurrió un error en la petición, intenta nuevamente'
    msg === 'Invalid username or password.'
      ? 'Datos de inicio de sesión incorrectos'
      : msg

    switch (error.response.status) {
      case 401:
        store.commit('showSnackBar', {
          text: msg,
          color: 'warning',
          icon: 'mdi-alert'
        })
        store.commit('account/logOut')
        router.push('login').catch(() => {})
        // return error;
        break
      case 500:
        store.commit('showSnackBar', {
          text: msg,
          color: 'error',
          icon: 'mdi-alert-circle'
        })
        // return error;
        break
      default:
        store.commit('showSnackBar', {
          text: msg,
          color: 'warning',
          icon: 'mdi-alert'
        })
        // return error;
        break
    }

    return Promise.reject(error)
  }
)

export default axios
