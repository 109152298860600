<template>
  <!-- src="@/assets/images/background.jpg" -->
  <div v-if="isLoggedIn">
    <v-app-bar dense height="70px" app>
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
      <div class="mainLogo">
        <img :src="getLogo" class="mainLogo" />
      </div>
      <v-spacer></v-spacer>
      <v-btn icon @click="$store.commit('account/toggleDarkTheme')">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-list nav dense>
        <v-list-item class="pr-0">
          <v-container
            class="d-flex justify-space-between align-center pa-0 ma-0"
          >
            <div class="mainLogo">
              <img :src="getLogo" class="mainLogo" />
            </div>
            <v-btn icon @click="drawer = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-container>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Inicio</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/myOpportunities">
          <v-list-item-icon>
            <v-icon>mdi-format-list-bulleted-square</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Mis oportunidades</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item link to="/newOpportunity">
          <v-list-item-icon>
            <v-icon>mdi-file-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nueva Oportunidad</v-list-item-title>
        </v-list-item> -->
        <v-list-item link to="/myIndicators">
          <v-list-item-icon>
            <v-icon>mdi-chart-line</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Métricas</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$store.commit('account/logOut')">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cerrar sesión</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <slot />
    </v-main>

    <!-- <v-footer app>
      <v-col class="text-center">
        {{ new Date().getFullYear() }} — <strong>Inndot</strong>
      </v-col>
    </v-footer> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      drawer: false,
      configDialog: false
    }
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    }
  },
  computed: {
    ...mapState('account', ['isLoggedIn', 'darkTheme']),
    isLoading() {
      return this.buttonLoading
    },
    getLogo() {
      let images = [
        // require('@/assets/images/inndot.png'),
        // require('@/assets/images/inndot_b.png')
        require('@/assets/images/inetum.png'),
        require('@/assets/images/inetum_b.png')
      ]
      if (this.darkTheme) {
        return images[1]
      } else {
        return images[0]
      }
    }
  },
  watch: {
    async isLoggedIn(state) {
      if (!state) {
        this.$router.push({ name: 'login' })
      }
    }
  },
  beforeCreate() {
    if (!this.$store.state.account.isLoggedIn) {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
